import Services from './base-services'

const service = new Services('course')
import mapParameters from '../utils/map-parameters'

export default {
    getAllByTeacher(teacherId: number) {
        const params = mapParameters({
            teacherId: teacherId ? teacherId : null,
        })
        return service.post(`getAllByTeacher?teacherId=${teacherId}`, params)
    },
    getUserTierListByCourseId(courseId: number) {
        const params = mapParameters({
            courseId: courseId ? courseId : null,
        })
        return service.get('getUserTierListByCourseId', params)
    },
    getCourseDetails(courseId: number) {
        const params = mapParameters({
            courseId: courseId ? courseId : null,
        })
        return service.get('getCourseDetails', params)
    }
}
