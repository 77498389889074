import Services from './base-services'

const service = new Services('bookUser')
import mapParameters from '../utils/map-parameters'

export default {
    getUserById(id: number) {
        return service.get(`getUserById?userId=${id}`, { userId: id })
    },
    getTeacherSummaryInfoByUserId(id: number) {
        return service.post(`getTeacherSummaryInfoByUserId?userId=${id}`, { userId: id })
    },
    getAllStudentListByCourseIdList(id: number, withTier?: boolean) {
        return service.post(`getAllStudentListByCourseIdList?teacherId=${id}&withTier=${withTier}`, { teacherId: id, withTier, })
    },
    getParamsOfUser(id: number) {
        return service.post(`getParamsOfUser`, { userId: id })
    },
    getMissionsOfUser(id: number) {
        return service.post(`getMissionsOfUser`, { userId: id })
    },
    getUsersToContact(data: any) {
        const params = mapParameters({
            userId: data && data.id ? data.id : null,
            pageNumber: data && data.init ? data.init : null,
            pageSize: data && data.finish ? data.finish : null,
        })

        return service.post(`getUsersToContact`, params)
    },
    updateUserPreferences(data: any) {
        return service.put(`updateUserPreferences`, data)
    },
}
