import axios from 'axios'

export default {
    async findBanners(platform: string) {
        const response = await axios.get(`${process.env.VUE_APP_BEEREADERS_MARKETING_INFO_URL}?${new Date().getTime()}`, {transformRequest: [(data, headers) => {
            delete headers.Authorization
            return data
        }]});
        const platformBanner = response.data.banners.filter( (banner: any) =>{
            return banner.platform === platform && banner.active === true
        })
        return platformBanner;
    }
}
