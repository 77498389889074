<template>
    <div></div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import users from '../../services/users'
import course from '../../services/course'
import auth from '../../services/auth'
import messages from '../../services/messages'
import parameters from '../../services/parameters'

import AnalyticEventsMixin from '@/mixins/analyticEvents' //eslint-disable-line
import { AnalyticEventTypes } from '../../constants/AnalyticEventTypes'

export default {
    name: 'Callback',
    mixins: [AnalyticEventsMixin],
    computed: {
        ...mapState({
            user: (state) => state.userData.user,
            token: (state) => state.token,
        }),
    },
    methods: {
        ...mapActions('userData', [
            'setUserInfo',
            'setCoursesByTeacher',
            'setStudentsByTeacher',
            'resetUserState',
            'setUserLoaded',
        ]),
        ...mapActions(['resetState', 'setToken', 'setMessages', 'setMarketingParams']),
        getUrlParameter(sParam) {
            const params = new URLSearchParams(window.location.search)
            return params.get(sParam)
        },
    },
    async created() {
        this.$loading(true)
        if ((this.$route.query.token && this.$route.query.token !== '') || !this.token) {
            this.resetState()
            this.resetUserState()
            this.setUserLoaded(false)
            const token = this.getUrlParameter('token')
            this.setToken(token)

            await auth
                .getInfoByToken()
                .then((response) => {
                    this.setUserInfo(response.content)
                })
                .catch((error) => {
                    console.error(error)
                    window.location.href = process.env.VUE_APP_BEEREADERS_LOGIN_URL
                })
        } else {
            window.location.href = process.env.VUE_APP_BEEREADERS_LOGIN_URL
        }

        const platformName = 'teachers'

        Promise.all([
            course.getAllByTeacher(this.user.id),
            users.getTeacherSummaryInfoByUserId(this.user.id),
            users.getAllStudentListByCourseIdList(this.user.id, true),
            messages.getInboxMessages(this.user.id),
            parameters.findBanners(platformName),
        ])
            .then((responseArray) => {
                const [
                    coursesByTeacher,
                    userInfo,
                    studentsByTeacher,
                    messagesResponse,
                    marketingParams,
                ] = responseArray
                this.setUserInfo(userInfo.content)
                this.setCoursesByTeacher(coursesByTeacher.content)
                this.setStudentsByTeacher(studentsByTeacher.content)
                this.setMessages(messagesResponse.content.content)
                this.setMarketingParams(marketingParams)
                this.setUserLoaded(true)

                this.$router.push('/teacher')
                this.createAnalyticEvent(AnalyticEventTypes.GoToQuickStats)
            })
            .catch((error) => console.error('Error al obtener la data inicial', error))
            .finally(() => {
                this.$loading(this.loadingState)
            })
    },
}
</script>

<style scoped></style>
